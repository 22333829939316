import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
   baseStyle: {
      fontSize: 'lg',
      fontWeight: 'semibold',
      lineHeight: '18px',
      textDecoration: 'none',
      py: '9px',
   },
   defaultProps: {
      variant: 'outline',
   },
   sizes: {
      xs: {
         fontSize: 'sm',
         lineHeight: '16px',
         minHeight: '24px',
         px: '8px',
      },
      sm: {
         fontSize: '13px',
         minHeight: '30px',
         py: '5px',
         px: '10px',
      },
      md: {
         fontSize: 'md',
         minHeight: '40px',
         px: '16px',
      },
      lg: {
         minHeight: '48px',
         px: '24px',
      },
   },
   variants: {
      cta: {
         bgColor: 'brand.500',
         color: 'white',
         _hover: {
            bgColor: 'brand.600',
         },
         _active: {
            bgColor: 'brand.700',
         },
         _focusVisible: {
            boxShadow: 'outline',
         },
      },
      secondary: {
         bgColor: 'gray.200',
         color: 'black',
         _hover: {
            bgColor: 'gray.300',
         },
         _active: {
            bgColor: 'gray.400',
         },
         _focusVisible: {
            boxShadow: 'outline',
         },
      },
      outline: {
         bgColor: 'white',
         borderColor: 'gray.300',
         color: 'gray.800',
      },
      white: {
         bgColor: 'white',
         color: 'brand.500',
         _hover: {
            bgColor: 'gray.100',
         },
         _active: {
            bgColor: 'gray.200',
         },
         _focusVisible: {
            boxShadow: 'outline',
         },
      },
   },
};
